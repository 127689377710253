import _addDays from 'date-fns/addDays';
import _differenceInCalendarDays from 'date-fns/differenceInDays';
import _differenceInYears from 'date-fns/differenceInYears';
import _isExists from 'date-fns/isExists';
import _isFuture from 'date-fns/isFuture';
import _isToday from 'date-fns/isToday';
import _isValid from 'date-fns/isValid';
import _isWithinInterval from 'date-fns/isWithinInterval';
import _subMonths from 'date-fns/subMonths';

export { default as addDays } from 'date-fns/addDays';
export { default as addHours } from 'date-fns/addHours';
export { default as addWeeks } from 'date-fns/addWeeks';
export { default as addMonths } from 'date-fns/addMonths';
export { default as addYears } from 'date-fns/addYears';
export { default as compareAsc } from 'date-fns/compareAsc';
export { default as compareDesc } from 'date-fns/compareDesc';
export { default as eachDayOfInterval } from 'date-fns/eachDayOfInterval';
export { default as isAfter } from 'date-fns/isAfter';
export { default as isBefore } from 'date-fns/isBefore';
export { default as isEqual } from 'date-fns/isEqual';
export { default as isSameYear } from 'date-fns/isSameYear';
export { default as isSameMonth } from 'date-fns/isSameMonth';
export { default as isSameWeek } from 'date-fns/isSameWeek';
export { default as startOfDay } from 'date-fns/startOfDay';
export { default as startOfWeek } from 'date-fns/startOfWeek';
export { default as startOfMonth } from 'date-fns/startOfMonth';
export { default as startOfYear } from 'date-fns/startOfYear';
export { default as endOfMonth } from 'date-fns/endOfMonth';
export { default as endOfYear } from 'date-fns/endOfYear';
export { default as getWeek } from 'date-fns/getWeek';
export { default as subYears } from 'date-fns/subYears';
export { default as subDays } from 'date-fns/subDays';
export { default as subWeeks } from 'date-fns/subWeeks';
export { default as subMonths } from 'date-fns/subMonths';

export { format, formatISO, formatShortISO } from './format';
export { parse, parseISO } from './parse';

export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_FORMAT_ISO = 'yyyy-MM-dd';

type Part = number | string;

export const fromArgs = (year: Part, month: Part, day: Part): Date =>
  new Date(Number(year), Number(month) - 1, Number(day));

export const fromObject = (value: { year: Part; month: Part; day: Part }): Date =>
  fromArgs(value.year, value.month, value.day);

const isExists = (year: Part, month: Part, day: Part): boolean =>
  _isExists(Number(year), Number(month) - 1, Number(day));

type IsValid = {
  (date: string | undefined | null | Date): boolean;
  (year: Part, month: Part, day: Part): boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValid: IsValid = (dateOrYear: any, month?: Part, day?: Part): boolean => {
  if (!dateOrYear) return false;
  if (month !== undefined && day !== undefined) return isExists(dateOrYear, month, day);
  if (dateOrYear instanceof Date) return _isValid(dateOrYear);
  if (typeof dateOrYear === 'string') {
    const [year, month, day] = dateOrYear.split('T')[0].split(/[-/]/);
    return isExists(year, month, day);
  }
  throw new Error('Invalid format to validate');
};

type IsFuture = {
  (year: Part, month: Part, day: Part): boolean;
};
export const isFuture: IsFuture = (year, month, day) => {
  return _isFuture(fromArgs(year, month, day));
};

export const isEighteenOrOlder = (year: Part, month: Part, day: Part) => {
  if (!year || !month || !day) return false;
  return yearsAgo(year, month, day) >= 18;
};

// Using start of day is more safe/correct for e.g. validations
export const daysFromNow = (days: number) => _addDays(new Date().setHours(0, 0, 0, 0), days);

export const differenceInDays = (left: string | Date, right?: string | Date) =>
  Math.abs(
    _differenceInCalendarDays(
      left instanceof Date ? left : new Date(left),
      right ? (right instanceof Date ? right : new Date(right)) : new Date(),
    ),
  );

const yearsAgo = (year: Part, month: Part, day: Part) => {
  return _differenceInYears(new Date(), fromArgs(year, month, day));
};

export const isLessThanYearAgo = (date: Date): boolean => {
  return _isWithinInterval(new Date(date), {
    start: _subMonths(Date.now(), 12),
    end: Date.now(),
  });
};

export const isToday = (date: Date): boolean => _isToday(date);

export {
  isTodayAmsterdam,
  formatAmsterdamDate,
  formatAmsterdamTime,
  formatShortISOAmsterdam,
  getAmsterdamHours,
} from './localized';
